import Tracker from "../Components/create-resume/tracker";
import Section from "../Components/section";

const CreateResume = () => {
  return (
    <div className="">
      <Section>
        <Tracker />
      </Section>
    </div>
  );
};

export default CreateResume;
