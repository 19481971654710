const ProgressBar = ({ progress }) => {
    // const container = {
    //         height: 20,
    //         width: "60%",
    //         backgroundColor: '#ffff',
    //         borderRadius: 50,
    //         margin: 50,
    //         position:"relative",
    //         border: '2px solid red',
    //         left: 210,
    //     }

    //     const bar = {
    //         height: "100%",
    //         width: `${progress}%`,
    //         backgroundColor: "#239ce2",
    //         borderRadius: "inherit",
    //     }

    //     const label = {
    //         padding: "1rem",
    //         color: "#000000",
    //     }
    //     return (
    //     <div style={container}>
    //         <div style={bar} role="progressbar"
    //             aria-valuenow={progress}
    //             aria-valuemin={0}
    //             aria-valuemax={100}>
    //             <span style={label} >{`${progress}%`}</span>
    //         </div>
    //     </div>
    //     );
    // };
}
export default ProgressBar;